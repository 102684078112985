import * as React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { DollarSign, ThumbsUp, Zap, Search, BookOpen, Clipboard, PieChart, Activity, LifeBuoy, CheckSquare } from 'react-feather';

const Benefits = () => {

    return (
        <Layout>
            <SEO title="Benefits" />
            <div className="content-wrapper">

                <div className="container-fluid">
                    <div className="page-intro">
                        <h1 className="h3">Over $10 billion has been invested in technologies that build relationships ... It’s time to focus on technology that can restore broken relationships and heal communities</h1>
                        <p className='lead'>We combine three strategies to assist large and small organizations rebuild relationships</p>
                    </div>
                </div>

            </div>
            <div className="section-primary">
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-8 mx-auto text-center">
                                <h4>Restorativ App is a mobile-only solution that can be deployed in minutes and provides the following benefits:</h4>
                            </div>
                        </div>
                        <div className="row text-center">
                            <div className="col-sm-6 col-md-4 mb-2 d-flex">
                                <div className="card">
                                    <div className="card-body">
                                        <DollarSign size="50" />
                                        <h4 className='mb-1 mt-2'>Reduced technology costs</h4>
                                        <p className='small mb-0'>A solution that can be accessed by anyone with a smartphone</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 col-md-4 mb-2 d-flex">
                                <div className="card">
                                    <div className="card-body">
                                        <ThumbsUp size="50" />
                                        <h4 className='mb-1 mt-2'>Intuitive</h4>
                                        <p className='small mb-0'>A solution that anyone can use via a  chat room interface</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 mb-2 d-flex">
                                <div className="card">
                                    <div className="card-body px-2">
                                        <Zap size="50" />
                                        <h4 className='mb-1 mt-2'>Empowering</h4>
                                        <p className='small mb-0'>A solution that brings the Affected Party into a safe and supportive environment</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 mb-2 d-flex">
                                <div className="card">
                                    <div className="card-body px-2">
                                        <Search size="50" />
                                        <h4 className='mb-1 mt-2'>Predictive</h4>
                                        <p className='small mb-0'>A solution that provides a predictive assessment for the most effective path to restoring the relationship</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 col-md-4 mb-2 d-flex">
                                <div className="card">
                                    <div className="card-body px-2">
                                        <BookOpen size="50" />
                                        <h4 className='mb-1 mt-2'>Learning</h4>
                                        <p className='small mb-0'>A solution that facilitates the most efficient learning process for the Responsible Party using predictive technology</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 mb-2 d-flex">
                                <div className="card">
                                    <div className="card-body">
                                        <Clipboard size="50" />
                                        <h4 className='mb-1 mt-2'>Reporting</h4>
                                        <p className='small mb-0'>A solution with a confidential audit trail of RJ sessions for future reference</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-white">
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-8 mx-auto text-center mb-4">
                                <h3>Restorativ Central is a fully integrated RJ online application that manages RJ processes for criminal justice organziations:</h3>
                                <p>Same benefits as the App plus:</p>
                            </div>
                        </div>
                        <div className="row text-center">
                            <div className="col-md-8 mx-auto">
                                <div className="row">
                                    <div className="col-md-6 mb-2">
                                        <div className="card">
                                            <div className="card-body">
                                                <PieChart size="50" />
                                                <h4 className='mb-1 mt-2'>Data analysis</h4>
                                                <p className='small mb-0'>Monitor and analyse RJ sessions</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <div className="card">
                                            <div className="card-body">
                                                <Activity size="50" />
                                                <h4 className='mb-1 mt-2'>Project management</h4>
                                                <p className='small mb-0'>For large complex RJ circles</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="section-accent">
                <div className="content-wrapper">
                    <div className="container-fluid py-3">
                        <h4 className='text-center mb-4'>Restorativ Consulting provides organisations two key benefits:</h4>
                        <div className="row text-center">
                            <div className="col-md-8 mx-auto text-body-color">
                                <div className="row">
                                    <div className="col-md-6 mb-2 d-flex">
                                        <div className="card">
                                            <div className="card-body px-2">
                                                <CheckSquare size="50" />
                                                <h4 className='mb-1 mt-2'>A Readiness Assessment</h4>
                                                <p className='small mb-0'>Restorativ provides both an assessment and a roadmap to RJ implementation.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-2 d-flex">
                                        <div className="card">
                                            <div className="card-body px-2">
                                                <LifeBuoy size="50" />
                                                <h4 className='mb-1 mt-2'>Implementation support</h4>
                                                <p className='small mb-0'>Providing support for the implementation of restorative practices and the use of Restorativ in the organization, speeding up the time to benefit.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Benefits
